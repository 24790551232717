import '../styles/table.scss';
import 'react-toggle/style.css';
import { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import Select from 'react-select';
import Toggle from 'react-toggle';
import dayjs from 'dayjs';
import { LicenseModal } from './LicenseModal';
import { AppContext } from '../context/AppContext';

export const Table = () => {
	const { licenses, setLicenses, setAppStatus, user, toast, setWorkflowStatutsOptions, workflowStatutsOptions } = useContext(AppContext);
	const [displayedLicenses, setDisplayedLicenses] = useState([]);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(true);
	const [licenseModalVisible, setLicenseModalVisible] = useState(false);
	const [selectedLicense, setSelectedLicense] = useState(undefined);
	const [filters, setFilters] = useState({
		reseller: { value: null, matchMode: FilterMatchMode.IN },
		customer: { value: null, matchMode: FilterMatchMode.IN },
		editor: { value: null, matchMode: FilterMatchMode.IN },
		product: { value: null, matchMode: FilterMatchMode.IN },
		expirationDate: { value: null, matchMode: FilterMatchMode.DATE_BEFORE },
		department: { value: null, matchMode: FilterMatchMode.IN },
		status: { value: 'Active', matchMode: FilterMatchMode.EQUALS },
		workflowStatut: { value: null, matchMode: FilterMatchMode.IN },
	});
	const [resellers, setResellers] = useState([]);
	const [customers, setCustomers] = useState([]);
	const [editors, setEditors] = useState([]);
	const [products, setProducts] = useState([]);
	const [departments, setDepartments] = useState([]);
	const defaultPaginatorRows = 100;
	const [paginatorRows, setPaginatorRows] = useState(defaultPaginatorRows);
	const activeMultiSelect = useRef([]);
	const tableStatus = useRef('Active');
	const table = useRef(null);
	const multiSelectInitialOptions = useRef({
		reseller: { active: [], inactive: [] },
		customer: { active: [], inactive: [] },
		editor: { active: [], inactive: [] },
		product: { active: [], inactive: [] },
		department: { active: [], inactive: [] },
	});
	const numberOfLicenses = useRef(0);
	const expirations = [
		{ value: '1 mois', label: '1 mois' },
		{ value: '2 mois', label: '2 mois' },
		{ value: '3 mois', label: '3 mois' },
	];
	const columns = [
		{ field: 'reseller', header: 'Revendeur', width: '10%', options: resellers },
		{ field: 'customer', header: 'Client', width: '25%', options: customers },
		{ field: 'editor', header: 'Éditeur', width: '10%', options: editors },
		{ field: 'product', header: 'Produit', width: '25%', options: products },
		{ field: 'licensesPurchased', header: 'Utilisée(s) / Achetée(s)', width: '5%' },
		{ field: 'expirationDate', header: 'Expiration', width: '5%', options: expirations },
		{ field: 'department', header: 'Département', width: '5%', options: departments },
		{ field: 'workflowStatut', header: 'Statut (workflow)', width: '5%', options: workflowStatutsOptions.tableOptions },
		{ field: 'workflowQuoteNumber', header: 'Numéro devis', width: '5%' },
		{ field: 'status', header: 'Statut', width: '5%' },
	];
	// Columns not displayed in datatable but present in csv export
	const hiddenColumns = [
		{ field: 'detailedStatus', header: 'Statut détaillé' },
		{ field: 'billing', header: 'Facturation' },
		{ field: 'serial', header: 'Serial' },
		{ field: 'commitment', header: 'Engagement' },
		{ field: 'endSaleDate', header: 'Date de fin de vente' },
		{ field: 'endSupportDate', header: 'Date de fin de support' },
		{ field: 'renewAutomatic', header: 'Renouvellement automatique' },
		{ field: 'manualPayment', header: 'Paiement manuel' },
		{ field: 'deviceCustomName', header: "Nom de l'appareil" },
	];

	const unknownLicenseValues = (license) => {
		const expectedKeys = [
			'licenseId',
			'reseller',
			'customer',
			'editor',
			'product',
			'status',
			'detailedStatus',
			'licensesPurchased',
			'licensesUsed',
			'billing',
			'serial',
			'expirationDate',
			'commitment',
			'department',
			'endSaleDate',
			'endSupportDate',
			'renewAutomatic',
			'manualPayment',
			'workflowStatut',
			'workflowQuoteNumber',
			'deviceCustomName',
		];
		const missingKeys = expectedKeys.filter((key) => !Object.keys(license).includes(key));
		missingKeys.length !== 0 && missingKeys.forEach((key) => (license[key] = 'Inconnu'));
	};

	const addSelectOptions = (license, initialization) => {
		const info = [
			['reseller', setResellers, license.reseller],
			['customer', setCustomers, license.customer],
			['editor', setEditors, license.editor],
			['product', setProducts, license.product],
			['department', setDepartments, license.department],
		];

		info.forEach((i) => {
			const [field, optionsFunction, licenseValue] = i;

			optionsFunction((optionsList) => {
				const newOption = { value: licenseValue, label: licenseValue };
				const newOptionInitialActiveFound = multiSelectInitialOptions.current[field].active.find((option) => option.value === newOption.value);
				const newOptionInitialInactiveFound = multiSelectInitialOptions.current[field].inactive.find((option) => option.value === newOption.value);
				const newOptionFound = optionsList.find((option) => option.value === newOption.value);

				if (initialization && license.status === 'Active' && newOptionInitialActiveFound === undefined) {
					multiSelectInitialOptions.current[field].active.push(newOption);
				}

				if (initialization && license.status === 'Inactive' && newOptionInitialInactiveFound === undefined) {
					multiSelectInitialOptions.current[field].inactive.push(newOption);
				}

				return license.status === tableStatus.current && newOptionFound === undefined ? [...optionsList, newOption] : optionsList;
			});
		});
	};

	const selectLicense = (license) => {
		const modalLicense = { ...license };
		modalLicense.expirationDate = dateBody(modalLicense);
		setSelectedLicense(modalLicense);
		setLicenseModalVisible(true);
	};

	const customPaginatorTemplate = {
		layout: 'CurrentPageReport',
		CurrentPageReport: (options) => {
			numberOfLicenses.current = options.totalRecords; // Update number of licenses displayed in export button
			const buttonLabel = `Afficher plus (${options.rows} / ${options.totalRecords})`;
			const buttonVisible = options.rows === options.totalRecords || options.totalRecords <= defaultPaginatorRows ? false : true;

			const numberRemainingLicenses = options.totalRecords - options.rows;
			const newValue = numberRemainingLicenses > defaultPaginatorRows ? options.rows + defaultPaginatorRows : options.rows + numberRemainingLicenses;
			return <Button visible={buttonVisible} label={buttonLabel} icon='pi pi-arrow-down' iconPos='right' severity='info' onClick={() => setPaginatorRows(newValue)} />;
		},
	};

	const exportTable = (event) => {
		switch (event.field) {
			case 'expirationDate':
				return !isNaN(event.data) ? event.data.toLocaleDateString() : "Pas d'expiration";
			case 'licensesPurchased':
				return ` ${event.rowData.licensesUsed} / ${event.data}`; // one space before text to prevent excel from formatting as date
			default:
				return event.data;
		}
	};

	const exportButton = () => {
		return <Button label={`EXPORT (${numberOfLicenses.current})`} severity='info' outlined icon='pi pi-file-excel' iconPos='right' onClick={() => table.current.exportCSV()} />;
	};

	const onValueChange = (tableValues) => {
		setDisplayedLicenses(tableValues);

		const multiSelectSetState = {
			reseller: setResellers,
			customer: setCustomers,
			editor: setEditors,
			product: setProducts,
			department: setDepartments,
		};

		Object.values(multiSelectSetState).forEach((msSetState) => msSetState([]));

		tableValues.forEach((license) => addSelectOptions(license, false));

		activeMultiSelect.current.forEach((filter) => {
			const msSetState = multiSelectSetState[filter];
			const options = tableStatus.current === 'Active' ? multiSelectInitialOptions.current[filter].active : multiSelectInitialOptions.current[filter].inactive;
			msSetState(options);
		});
	};

	const onFilter = (tableInfo) => {
		const filtersToVerify = ['reseller', 'customer', 'editor', 'product', 'department'];
		activeMultiSelect.current = [];
		Object.entries(tableInfo.filters).forEach(([filter, value]) => {
			if (filtersToVerify.includes(filter) && value.value != null && value.value?.length > 0) {
				activeMultiSelect.current.push(filter);
			}
		});

		tableStatus.current = tableInfo.filters.status.value;
		setFilters(tableInfo.filters);
		setPaginatorRows(defaultPaginatorRows);
	};

	const multiSelectFilter = (settings, options) => {
		return (
			<Select
				options={options}
				isMulti
				isClearable={true}
				onChange={(selectedOptions) => {
					const value = selectedOptions.map((option) => option.value);
					settings.filterApplyCallback(value);
				}}
			/>
		);
	};

	const statusFilter = (settings) => {
		return (
			<div className='status-filter'>
				<Toggle
					defaultChecked={true}
					icons={false}
					onChange={(e) => {
						const value = e.target.checked ? 'Active' : 'Inactive';
						settings.filterApplyCallback(value);
					}}
				/>
			</div>
		);
	};

	const dateFilter = (settings, options) => {
		return (
			<Select
				options={options}
				isClearable={true}
				onChange={(selectedOption) => {
					let filterDate = null;

					if (selectedOption !== null) {
						const option = selectedOption.value;
						filterDate = dayjs();

						switch (option) {
							case '1 mois':
								filterDate = filterDate.add(1, 'month').toDate();
								break;
							case '2 mois':
								filterDate = filterDate.add(2, 'month').toDate();
								break;
							case '3 mois':
								filterDate = filterDate.add(3, 'month').toDate();
								break;
							default:
								break;
						}
					}

					settings.filterApplyCallback(filterDate);
				}}
			/>
		);
	};

	const licensesPurchasedBody = (license) => {
		if (license.licensesUsed !== 'Inconnu') {
			const textColor = license.licensesUsed !== license.licensesPurchased ? '#FF6800' : 'black';
			return (
				<>
					<span style={{ color: textColor }}>{license.licensesUsed}</span>
					<span> / {license.licensesPurchased}</span>
				</>
			);
		}

		return license.licensesPurchased;
	};

	const statusBody = (license) => {
		return license.status === 'Active' ? <span className='sticker green'></span> : <span className='sticker gray'></span>;
	};

	const dateBody = (license) => {
		return !isNaN(license.expirationDate) ? license.expirationDate.toLocaleDateString() : "Pas d'expiration";
	};

	useEffect(() => {
		const adaptLicenses = (licenses) => {
			const adaptedLicenses = licenses.map((license) => {
				license.expirationDate = new dayjs(license.expirationDate).toDate(); // Invalid Date when "Pas d'expiration"
				unknownLicenseValues(license);
				addSelectOptions(license, true);
				return license;
			});

			setDisplayedLicenses(adaptedLicenses.filter((lcs) => lcs.status === 'Active'));

			return adaptedLicenses;
		};

		const getLicenses = async () => {
			const options = {
				method: 'GET',
				headers: {
					'C2i-User': user.name,
					Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
				},
			};

			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/licenses`, options);
				if (response.ok) {
					const data = await response.json();
					setLicenses(adaptLicenses(data.data));
					setLoading(false);
					setAppStatus(data.appStatus);
					data.appStatus.down.forEach((microservice) => {
						toast.current.show({ severity: 'error', detail: `${microservice} indisponible`, life: 5000 });
					});
				} else {
					setError(true);
				}
			} catch (error) {
				console.log(error);
				setError(true);
			}
		};

		const getWorkflowStatutsOptions = async () => {
			const options = {
				method: 'GET',
				headers: {
					'C2i-User': user.name,
					Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
				},
			};

			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/statuts`, options);
				if (response.ok) {
					const data = await response.json();
					const tableOptions = [];
					const modalOptions = [];
					data.data.forEach((statut) => {
						tableOptions.push({ value: statut.label, label: statut.label });
						modalOptions.push({ value: statut.id, label: statut.label });
					});
					setWorkflowStatutsOptions({ tableOptions, modalOptions });
				} else {
					toast.current.show({ severity: 'error', detail: 'Erreur lors de la récupération des données !', life: 3000 });
				}
			} catch (error) {
				console.log(error);
				toast.current.show({ severity: 'error', detail: 'Erreur lors de la récupération des données !', life: 3000 });
			}
		};

		getLicenses();
		getWorkflowStatutsOptions();
	}, [user]);

	if (error) {
		return <Message severity='error' text='Erreur lors de la récupération des données !' />;
	}

	return (
		<>
			<DataTable
				csvSeparator=';'
				ref={table}
				value={licenses}
				loading={loading}
				emptyMessage='Aucunes licences trouvées.'
				stripedRows
				removableSort
				selectionMode='single'
				onSelectionChange={(e) => selectLicense(e.value)}
				filters={filters}
				filterDisplay='row'
				onFilter={(e) => onFilter(e)}
				onValueChange={(tableValues) => onValueChange(tableValues)}
				paginator
				rows={paginatorRows}
				paginatorTemplate={customPaginatorTemplate}
				paginatorRight={exportButton}
				paginatorLeft={<></>}
				exportFunction={(e) => exportTable(e)}
				exportFilename={'licences_export_' + dayjs().format('DD-MM-YYYY-HH-mm')}
			>
				{columns.map((col) => {
					const baseColumnProps = {
						key: col.field,
						field: col.field,
						header: col.header,
						style: { width: col.width },
						align: 'center',
						sortable: true,
						filter: true,
						showFilterMenu: false,
						showClearButton: false,
					};

					switch (col.field) {
						case 'licensesPurchased':
							return <Column {...baseColumnProps} filter={false} body={licensesPurchasedBody} />;
						case 'status':
							return <Column {...baseColumnProps} body={statusBody} filterElement={(settings) => statusFilter(settings)} />;
						case 'expirationDate':
							return <Column {...baseColumnProps} body={dateBody} filterElement={(settings) => dateFilter(settings, col.options)} />;
						case 'workflowQuoteNumber':
							return <Column {...baseColumnProps} filter={false} />;
						default:
							return <Column {...baseColumnProps} filterElement={(settings) => multiSelectFilter(settings, col.options)} />;
					}
				})}

				{hiddenColumns.map((col) => {
					return <Column key={col.field} field={col.field} header={col.header} hidden />;
				})}
			</DataTable>
			{licenseModalVisible && (
				<LicenseModal setLicenseModalVisible={setLicenseModalVisible} selectedLicense={selectedLicense} selectLicense={selectLicense} displayedLicenses={displayedLicenses} />
			)}
		</>
	);
};
