import '../styles/bitdefenderpopup.scss';
import { useState, useContext } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AppContext } from '../context/AppContext';
import dayjs from 'dayjs';

export const BitdefenderPopup = ({ setBitdefenderPopupVisible }) => {
	const [code, setCode] = useState('');
	const [loading, setLoading] = useState(false);
	const { user, toast } = useContext(AppContext);

	const sendCode = async () => {
		setLoading(true);

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
				'C2i-User': user.name,
				Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
			},
			body: JSON.stringify({
				code: code,
			}),
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/bitdefender/file`, options);
			if (response.ok) {
				localStorage.setItem('BitdefenderLastUpdate', dayjs().add('6', 'hour').toISOString());
				toast.current.show({ severity: 'success', detail: "Mise à jour de l'export Bitdefender effectué !", life: 3000 });
				setBitdefenderPopupVisible(false);
			} else {
				toast.current.show({ severity: 'error', detail: "Erreur lors de la mise à jour de l'export Bitdefender !", life: 3000 });
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
			toast.current.show({ severity: 'error', detail: "Erreur lors de la mise à jour de l'export Bitdefender !", life: 3000 });
		}
	};

	return (
		<div className='bitdefender-popup-background'>
			<div className='bitdefender-popup'>
				<div className='bitdefender-popup-header'>
					<h1>Fichier Bitdefender</h1>
					<Button className='bitdefender-popup-close-button' icon='pi pi-times' onClick={() => setBitdefenderPopupVisible(false)} />
				</div>
				<p>Veuillez entrer le code MFA Bitdefender :</p>
				<InputText value={code} onChange={(e) => setCode(e.target.value)} />
				<Button className='bitdefender-popup-send-button' label='Envoyer' onClick={sendCode} loading={loading} />
			</div>
		</div>
	);
};
