import '../styles/login.scss';
import c2iLogo from '../assets/c2i_logo.png';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

export const Login = () => {
	const { instance } = useMsal();

	const handleLogin = () => {
		instance.loginRedirect(loginRequest).catch((error) => {
			console.log(error);
		});
	};

	return (
		<div className='login-page'>
			<div className='connection'>
				<img src={c2iLogo} alt='Logo C2i' />
				<h1>C2i Assets Management</h1>
				<p>La plateforme interne de gestion des assets de C2i.</p>
				<Divider />
				<h2>Connexion</h2>
				<Button label='Se connecter avec Microsoft 365' severity='success' outlined icon='pi pi-microsoft' iconPos='left' onClick={() => handleLogin()} />
			</div>
		</div>
	);
};
