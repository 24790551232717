import { createContext, useState, useRef } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
	const [appStatus, setAppStatus] = useState({ up: [], down: [] });
	const [user, setUser] = useState({ id: '', name: '', email: '' });
	const [workflowStatutsOptions, setWorkflowStatutsOptions] = useState({ tableOptions: [], modalOptions: [] });
	const [licenses, setLicenses] = useState([]);
	const toast = useRef(null);

	return <AppContext.Provider value={{ appStatus, setAppStatus, user, setUser, toast, workflowStatutsOptions, setWorkflowStatutsOptions, licenses, setLicenses }}>{children}</AppContext.Provider>;
};
