import '../styles/comments.scss';
import { useEffect, useState, useRef, useContext } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import dayjs from 'dayjs';
import { Comment } from './Comment';
import { AppContext } from '../context/AppContext';

export const CommentsZone = ({ licenseId }) => {
	const { user, toast } = useContext(AppContext);
	const [comments, setComments] = useState([]);
	const [subComments, setSubComments] = useState([]);
	const [commentValue, setCommentValue] = useState('');
	const commentsRef = useRef(null);
	const firedScrollRef = useRef(false);

	const adaptComments = (comments) => {
		return comments.map((comment) => {
			comment.creation_date = new dayjs(comment.creation_date).toDate();
			return comment;
		});
	};

	const getComments = async () => {
		const options = {
			method: 'GET',
			headers: {
				'C2i-User': user.name,
				Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
			},
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/comments?licenseId=${licenseId}`, options);
			if (response.ok) {
				const data = await response.json();
				const cmts = [];
				const subCmts = [];
				data.data.forEach((comment) => {
					comment.id_comment_parent === null ? cmts.push(comment) : subCmts.push(comment);
				});
				setComments(adaptComments(cmts));
				setSubComments(adaptComments(subCmts));
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleAddComment = async (idCommentParent, subCommentValue) => {
		if (commentValue !== '' || subCommentValue) {
			const comment = {
				content: subCommentValue ?? commentValue,
				author: user.id,
				id_comment_parent: idCommentParent ?? null,
			};

			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
					'C2i-User': user.name,
					Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
				},
				body: JSON.stringify(comment),
			};

			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/comments?licenseId=${licenseId}`, options);
				if (response.ok) {
					subCommentValue === undefined && setCommentValue('');
					getComments();
					toast.current.show({ severity: 'success', detail: 'Le commentaire a bien été créé !', life: 3000 });
				}
			} catch (error) {
				console.log(error);
			}
		}
	};

	const handleDeleteComment = async (commentId) => {
		const options = {
			method: 'DELETE',
			headers: {
				'C2i-User': user.name,
				Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
			},
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/comments/${commentId}`, options);
			if (response.ok) {
				getComments();
				toast.current.show({ severity: 'success', detail: 'Le commentaire a bien été supprimé !', life: 3000 });
			}
		} catch (error) {
			console.log(error);
		}
	};

	const scrollToLastComment = () => {
		const lastComment = commentsRef.current?.lastElementChild;
		lastComment?.scrollIntoView({ behavior: 'instant' });
	};

	useEffect(() => {
		getComments();
	}, [licenseId]);

	useEffect(() => {
		if (comments.length > 0 && firedScrollRef.current === false) {
			scrollToLastComment();
			firedScrollRef.current = true;
		}
	}, [comments]);

	return (
		<div className='comments-zone'>
			<div className='comments-zone-body' ref={commentsRef}>
				{comments.map((cmt) => {
					return <Comment key={cmt.id} comment={cmt} allSubComments={subComments} handleAddComment={handleAddComment} handleDeleteComment={handleDeleteComment} />;
				})}
			</div>
			<div className='comments-zone-footer'>
				<InputTextarea autoResize placeholder='Ecrire une remarque...' value={commentValue} onChange={(e) => setCommentValue(e.target.value)} />
				<button onClick={() => handleAddComment()}>
					<span>Envoyer</span>
					<i className='pi pi-send'></i>
				</button>
			</div>
		</div>
	);
};
