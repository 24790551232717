import '../styles/licensemodal.scss';
import { useState, useRef, useEffect, useContext } from 'react';
import arrowsphereLogo from '../assets/arrowsphere_logo.png';
import infinigateLogo from '../assets/infinigate_logo.png';
import ingramLogo from '../assets/ingram_logo.png';
import mc3Logo from '../assets/mc3_logo.png';
import arrowLogo from '../assets/arrow_logo.png';
import ovhLogo from '../assets/ovh_logo.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CommentsZone } from './CommentsZone';
import { WorkflowZone } from './WorkflowZone';
import { Menu } from 'primereact/menu';
import { AppContext } from '../context/AppContext';
import dayjs from 'dayjs';
import { Dropdown } from 'primereact/dropdown';

export const LicenseModal = ({ setLicenseModalVisible, selectedLicense, selectLicense, displayedLicenses }) => {
	const { user, toast } = useContext(AppContext);
	const customerMenu = useRef(null);
	const departmentMenu = useRef(null);
	const [licensePosition, setLicensePosition] = useState(0);
	const [editCustomer, setEditCustomer] = useState(false);
	const [editDepartment, setEditDepartment] = useState(false);
	const [editCustomerValue, setEditCustomerValue] = useState('');
	const [editDepartmentValue, setEditDepartmentValue] = useState(null);
	const [licenseWorkflowArchives, setLicenseWorkflowArchives] = useState([]);
	const [tabMenuIndex, setTabMenuIndex] = useState(0);
	const editMenuItems = [
		{
			label: 'Enregistrer pour le client',
			icon: 'pi pi-building',
			command: () => updateLicenseInfo('client'),
		},
		{
			label: 'Enregistrer pour la licence',
			icon: 'pi pi-file',
			command: () => updateLicenseInfo('licence'),
		},
	];
	const departmentSelectOptions = [
		{ label: 'Guadeloupe', value: 'Guadeloupe' },
		{ label: 'Martinique', value: 'Martinique' },
		{ label: 'Guyane', value: 'Guyane' },
		{ label: 'Réunion', value: 'Réunion' },
	];

	const updateLicenseInfo = async (saveType) => {
		if ((editCustomer && editCustomerValue === '') || (editDepartment && editDepartmentValue === null)) {
			toast.current.show({ severity: 'error', detail: 'Modification vide !', life: 3000 });
			return;
		}

		const type = editCustomer ? 'customer' : 'department';
		const url = `${process.env.REACT_APP_BACKEND_URL}/api/correspondences?type=${type}`;
		const headers = {
			'Content-Type': 'application/json;charset=utf-8',
			'C2i-User': user.name,
			Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
		};
		const body = JSON.stringify({
			correct: editCustomer ? editCustomerValue : editDepartmentValue,
			customer: saveType === 'client' ? selectedLicense.initialCustomer ?? selectedLicense.customer : null,
			license_id: saveType === 'licence' ? selectedLicense.licenseId : null,
		});

		try {
			let response = await fetch(url, { method: 'PUT', headers, body });
			const data = await response.json();

			if (data === 'no data') {
				response = await fetch(url, { method: 'POST', headers, body });
			}

			if (response.ok) {
				toast.current.show({ severity: 'success', detail: 'Modifications enregistrées !', life: 3000 });
				window.location.reload();
			} else {
				toast.current.show({ severity: 'error', detail: "Erreur lors de l'enregistrement !", life: 3000 });
			}
		} catch (error) {
			console.error(error);
			toast.current.show({ severity: 'error', detail: "Erreur lors de l'enregistrement !", life: 3000 });
		}
	};

	const getLicenseWorkflowArchives = async () => {
		const options = {
			method: 'GET',
			headers: {
				'C2i-User': user.name,
				Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
			},
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/licenses/${selectedLicense.licenseId}/workflow/archive`, options);
			if (response.ok) {
				const data = await response.json();
				setLicenseWorkflowArchives(data.data);
			} else {
				toast.current.show({ severity: 'error', detail: 'Erreur lors de la récupération des données !', life: 3000 });
			}
		} catch (error) {
			console.log(error);
			toast.current.show({ severity: 'error', detail: 'Erreur lors de la récupération des données !', life: 3000 });
		}
	};

	const showCopySuccess = () => {
		toast.current.show({ severity: 'info', detail: "L'élément a bien été copié !", life: 3000 });
	};

	const CopyButton = ({ info }) => {
		return (
			<CopyToClipboard text={info}>
				<button className='copy-button' onClick={showCopySuccess}>
					<i className='pi pi-copy'></i>
				</button>
			</CopyToClipboard>
		);
	};

	const getResellerLogo = () => {
		switch (selectedLicense.reseller) {
			case 'ArrowSphere':
				return arrowsphereLogo;
			case 'Infinigate':
				return infinigateLogo;
			case 'Ingram':
				return ingramLogo;
			case 'MC3':
				return mc3Logo;
			case 'Arrow':
				return arrowLogo;
			case 'OVH':
				return ovhLogo;
			default:
				break;
		}
	};

	useEffect(() => {
		setEditCustomer(false);
		setEditDepartment(false);
		setEditCustomerValue('');
		setEditDepartmentValue(null);
		getLicenseWorkflowArchives();
	}, [selectedLicense]);

	useEffect(() => {
		const licenseIndex = displayedLicenses.findIndex((lcs) => lcs.licenseId === selectedLicense.licenseId);
		setLicensePosition(licenseIndex);
	}, [displayedLicenses, selectedLicense]);

	return (
		<div className='license-modal-background'>
			<div className='license-modal'>
				<div className='license-modal-actions'>
					<div className='arrows'>
						{licensePosition > 0 && (
							<button className='arrow' onClick={() => selectLicense(displayedLicenses[licensePosition - 1])}>
								<i className='pi pi-arrow-left'></i>
							</button>
						)}
						{licensePosition < displayedLicenses.length - 1 && (
							<button className='arrow' onClick={() => selectLicense(displayedLicenses[licensePosition + 1])}>
								<i className='pi pi-arrow-right'></i>
							</button>
						)}
					</div>
					<button className='close-button' onClick={() => setLicenseModalVisible(false)}>
						<i className='pi pi-times'></i>
					</button>
				</div>
				<div className='license-modal-infos'>
					<div className='license-info'>
						<div className='zone-1'>
							<div>
								<div className='edit-block'>
									{editCustomer ? (
										<>
											<button
												onClick={() => {
													setEditCustomer(false);
													setEditCustomerValue('');
												}}
											>
												<i className='pi pi-times'></i>
											</button>
											<input value={editCustomerValue} onChange={(e) => setEditCustomerValue(e.target.value)} />
											<button onClick={(event) => customerMenu.current.toggle(event)}>
												<i className='pi pi-save'></i>
											</button>
											<Menu model={editMenuItems} popup ref={customerMenu} />
										</>
									) : (
										<>
											<strong>{selectedLicense.customer}</strong>
											<button
												onClick={() => {
													setEditDepartmentValue(null);
													setEditDepartment(false);
													setEditCustomer(true);
												}}
											>
												<i className='pi pi-pencil'></i>
											</button>
										</>
									)}
								</div>
								{selectedLicense.initialCustomer && <span className='initial-value'>({selectedLicense.initialCustomer})</span>}
								<div className='edit-block'>
									{editDepartment ? (
										<>
											<button
												onClick={() => {
													setEditDepartment(false);
													setEditDepartmentValue(null);
												}}
											>
												<i className='pi pi-times'></i>
											</button>
											<Dropdown value={editDepartmentValue} onChange={(e) => setEditDepartmentValue(e.value)} options={departmentSelectOptions} placeholder='Département...' />
											<button onClick={(event) => departmentMenu.current.toggle(event)}>
												<i className='pi pi-save'></i>
											</button>
											<Menu model={editMenuItems} popup ref={departmentMenu} />
										</>
									) : (
										<>
											<span>
												<i className='pi pi-map-marker'></i> {selectedLicense.department}
											</span>
											<button
												onClick={() => {
													setEditCustomerValue('');
													setEditCustomer(false);
													setEditDepartment(true);
												}}
											>
												<i className='pi pi-pencil'></i>
											</button>
										</>
									)}
								</div>
								{selectedLicense.initialDepartment && <span className='initial-value'>({selectedLicense.initialDepartment})</span>}
							</div>
							<div>
								<strong>{selectedLicense.product}</strong>
								<span>{selectedLicense.editor}</span>
							</div>
							<div className='reseller'>
								<img src={getResellerLogo()} alt='Logo revendeur' />
								<span>{selectedLicense.reseller}</span>
							</div>
						</div>

						<div className='zone-2'>
							<div className='status'>
								{selectedLicense.status === 'Active' ? <i className='pi pi-check'></i> : <i className='pi pi-times'></i>}
								<strong>{selectedLicense.status}</strong>
							</div>
							<div className='stock'>
								<span>
									<strong className={selectedLicense.licensesUsed !== selectedLicense.licensesPurchased ? 'different' : ''}>{selectedLicense.licensesUsed}</strong>
									<strong> / {selectedLicense.licensesPurchased}</strong>
								</span>
								<span>Utilisées / Achetées</span>
							</div>
							<div className='expiration'>
								<strong>{selectedLicense.expirationDate}</strong>
								<span>Date d'expiration</span>
							</div>
						</div>

						<div className='serial'>
							<span>{selectedLicense.serial}</span>
							<CopyButton info={selectedLicense.serial} />
						</div>

						<div className='zone-3'>
							<div className='others-info'>
								<div>
									<span>Nom de l'appareil</span>
									<strong>{selectedLicense.deviceCustomName}</strong>
								</div>
								<div>
									<span>Date de fin de vente</span>
									<strong>{selectedLicense.endSaleDate}</strong>
								</div>
								<div>
									<span>Date de fin de support</span>
									<strong>{selectedLicense.endSupportDate}</strong>
								</div>
								<div>
									<span>Statut détaillé</span>
									<strong>{selectedLicense.detailedStatus}</strong>
								</div>
								<div>
									<span>Facturation</span>
									<strong>{selectedLicense.billing}</strong>
								</div>
								<div>
									<span>Engagement</span>
									<strong>{selectedLicense.commitment}</strong>
								</div>
								<div>
									<span>Renouvellement automatique</span>
									<strong>{selectedLicense.renewAutomatic !== 'Inconnu' ? (selectedLicense.renewAutomatic ? 'Oui' : 'Non') : selectedLicense.renewAutomatic}</strong>
								</div>
								<div>
									<span>Paiement manuel</span>
									<strong>{selectedLicense.manualPayment !== 'Inconnu' ? (selectedLicense.manualPayment ? 'Oui' : 'Non') : selectedLicense.manualPayment}</strong>
								</div>
								<div className='license-id'>
									<span>ID Licence</span>
									<span>
										<strong>{selectedLicense.licenseId.length > 15 ? selectedLicense.licenseId.slice(0, 15) + '...' : selectedLicense.licenseId}</strong>
										<CopyButton info={selectedLicense.licenseId} />
									</span>
								</div>
							</div>
						</div>
						<WorkflowZone licenseId={selectedLicense.licenseId} getLicenseWorkflowArchives={getLicenseWorkflowArchives} />
					</div>
					<div>
						<div className='license-modal-tab-menu'>
							<span className={tabMenuIndex === 0 ? 'active' : undefined} onClick={() => setTabMenuIndex(0)}>
								Commentaires
							</span>
							<span className={tabMenuIndex === 1 ? 'active' : undefined} onClick={() => setTabMenuIndex(1)}>
								Archives
							</span>
						</div>
						{tabMenuIndex === 0 ? (
							<CommentsZone licenseId={selectedLicense.licenseId} />
						) : (
							<div className='workflow-archive-zone'>
								{licenseWorkflowArchives.map((lwa) => {
									return (
										<div key={lwa.id} className='workflow-archive'>
											<span>
												Devis : <strong>{lwa.quote_number}</strong>
											</span>
											<span>
												Facture : <strong>{lwa.invoice_number}</strong>
											</span>
											<span className='workflow-archive-footer'>
												<span>
													Statut : <strong>{lwa.statut}</strong>
												</span>
												<i>{dayjs(lwa.creation_date).format('DD/MM/YYYY')}</i>
											</span>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
