import './styles/index.scss';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import { App } from './components/App';
import { AppProvider } from './context/AppContext';
import { locale, addLocale } from 'primereact/api';
import french from './fr.json';

addLocale('fr', french);
locale('fr');

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<AppProvider>
				<App />
			</AppProvider>
		</MsalProvider>
	</React.StrictMode>
);
