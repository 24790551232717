import '../styles/header.scss';
import avatar from '../assets/avatar.png';
import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { Button } from 'primereact/button';

export const Header = () => {
	const { instance } = useMsal();
	const { appStatus, user } = useContext(AppContext);

	const handleLogout = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: '/',
		});
	};

	return (
		<header>
			<h1>C2i Assets Management</h1>
			<div>
				<div className='app-status'>
					<Button className='app-status-button' label='Statuts' severity='info' icon={appStatus.down.length > 0 ? 'pi pi-times' : 'pi pi-check'} outlined />
					<div className='app-status-result'>
						{appStatus.down.map((element) => (
							<span key={element}>
								<i className='pi pi-times' style={{ color: 'red' }}></i>
								{element}
							</span>
						))}
						{appStatus.up.map((element) => (
							<span key={element}>
								<i className='pi pi-check' style={{ color: 'green' }}></i>
								{element}
							</span>
						))}
					</div>
				</div>
				<div className='profile'>
					<div>
						<span>{user.name}</span>
						<img src={avatar} alt='avatar' />
					</div>
					<button onClick={() => handleLogout()}>Se déconnecter</button>
				</div>
			</div>
		</header>
	);
};
