import { useState, useEffect, useContext } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Menu } from 'primereact/menu';
import { AppContext } from '../context/AppContext';

export const Comment = ({ comment, allSubComments, handleAddComment, handleDeleteComment }) => {
	const { user } = useContext(AppContext);
	const [subComments, setSubComments] = useState([]);
	const [showCommentTextarea, setShowCommentTextarea] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [subCommentValue, setSubCommentValue] = useState('');
	const items = [
		{
			label: 'Répondre',
			icon: 'pi pi-fw pi-plus',
			command: () => {
				setShowMenu(!showMenu);
				setShowCommentTextarea(!showCommentTextarea);
			},
		},
		{
			label: 'Supprimer',
			icon: 'pi pi-fw pi-trash',
			command: () => {
				handleDeleteComment(comment.id);
			},
			disabled: comment.author === user.name ? false : true,
		},
	];

	const handleAddSubComment = () => {
		if (subCommentValue !== '') {
			handleAddComment(comment.id, subCommentValue);
			setSubCommentValue('');
			setShowCommentTextarea(false);
		}
	};

	const handleShowMenu = () => {
		setShowCommentTextarea(false);
		setShowMenu(!showMenu);
	};

	const CommentBlock = ({ cmt, isSub }) => {
		const formattedDate = cmt.creation_date.toLocaleDateString().replaceAll('/', '-');

		return (
			<>
				<span className='comment-infos'>
					{cmt.author} le {formattedDate}
				</span>
				<div className='comment-content'>
					<p>{cmt.content}</p>
					{!isSub && (
						<button onClick={() => handleShowMenu()}>
							<i className='pi pi-ellipsis-v'></i>
						</button>
					)}
				</div>
			</>
		);
	};

	useEffect(() => {
		setSubComments(allSubComments.filter((sbCmt) => sbCmt.id_comment_parent === comment.id));
	}, [allSubComments, comment]);

	return (
		<>
			<div className='comment'>
				<CommentBlock cmt={comment} />
				{showMenu && <Menu className='menu' model={items} />}
				{showCommentTextarea && (
					<div className='add-subcomment-zone'>
						<InputTextarea autoResize placeholder='Écrire...' value={subCommentValue} onChange={(e) => setSubCommentValue(e.target.value)} />
						<div className='add-subcomment-zone-buttons'>
							<button onClick={() => setShowCommentTextarea(false)}>
								<i className='pi pi-times'></i>
							</button>
							<button onClick={() => handleAddSubComment()}>
								<i className='pi pi-reply'></i>
							</button>
						</div>
					</div>
				)}
			</div>
			{subComments.map((sbCmt) => {
				return (
					<div key={sbCmt.id} className='comment sub'>
						<CommentBlock cmt={sbCmt} isSub />
					</div>
				);
			})}
		</>
	);
};
