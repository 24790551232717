import '../styles/workflow.scss';
import { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { AppContext } from '../context/AppContext';
import dayjs from 'dayjs';

export const WorkflowZone = ({ licenseId, getLicenseWorkflowArchives }) => {
	const { user, toast, workflowStatutsOptions, setLicenses } = useContext(AppContext);
	const [quoteNumber, setQuoteNumber] = useState('');
	const [invoiceNumber, setInvoiceNumber] = useState('');
	const [statut, setStatut] = useState(null);
	const [nextActionDate, setNextActionDate] = useState(null);
	const [workflowExist, setWorkflowExist] = useState(false);
	const [workflowFinished, setWorkflowFinished] = useState(false);
	const [formChanged, setFormChanged] = useState(false);
	const [initialValues, setInitialValues] = useState({});

	const getLicenseWorkflow = async () => {
		const options = {
			method: 'GET',
			headers: {
				'C2i-User': user.name,
				Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
			},
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/licenses/${licenseId}/workflow`, options);
			if (response.ok) {
				const data = await response.json();
				if (data === 'no data') {
					setWorkflowFinished(false);
					setWorkflowExist(false);
					setQuoteNumber('');
					setInvoiceNumber('');
					setStatut(null);
					setNextActionDate(null);
					setInitialValues({ quoteNumber: '', invoiceNumber: '', statut: null, nextActionDate: null });
					updateWorkflowDatatableValues(null, '');
				} else {
					const dataStatut = workflowStatutsOptions.modalOptions.find((o) => o.value === data['statut']);
					dataStatut?.label === 'Validé' || dataStatut?.label === 'Refusé' ? setWorkflowFinished(true) : setWorkflowFinished(false);
					const dataNextActionDate = data['next_action_date'] ? dayjs(data['next_action_date']).toDate() : null;
					setWorkflowExist(true);
					setQuoteNumber(data['quote_number']);
					setInvoiceNumber(data['invoice_number']);
					setStatut(dataStatut);
					setNextActionDate(dataNextActionDate);
					setInitialValues({ quoteNumber: data['quote_number'], invoiceNumber: data['invoice_number'], statut: dataStatut, nextActionDate: dataNextActionDate });
					updateWorkflowDatatableValues(dataStatut, data['quote_number']);
				}
			} else {
				toast.current.show({ severity: 'error', detail: 'Erreur lors de la récupération des données !', life: 3000 });
			}
		} catch (error) {
			console.log(error);
			toast.current.show({ severity: 'error', detail: 'Erreur lors de la récupération des données !', life: 3000 });
		}
	};

	const deleteWorkflow = async () => {
		const options = {
			method: 'DELETE',
			headers: {
				'C2i-User': user.name,
				Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
			},
		};
		return await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/licenses/${licenseId}/workflow`, options);
	};

	const updateWorkflowDatatableValues = (workflowStatut, workflowQuoteNumber) => {
		// Update values in datatable (to prevent page refresh just for get the right data in datatable)
		setLicenses((licenses) => {
			return licenses.map((lcs) => {
				if (lcs.licenseId === licenseId) {
					lcs.workflowStatut = workflowStatut ? workflowStatut.label : 'Inconnu';
					lcs.workflowQuoteNumber = workflowQuoteNumber || 'Inconnu';
				}
				return lcs;
			});
		});
	};

	const updateWorkflow = async (cancel) => {
		let toastNotification = { severity: 'error', detail: "Erreur lors de l'enregistrement !", life: 3000 };

		if (!quoteNumber && !invoiceNumber && !statut && !nextActionDate) {
			try {
				const response = await deleteWorkflow();
				if (response.ok) {
					getLicenseWorkflow();
					toastNotification = { severity: 'success', detail: 'Informations enregistrées !', life: 3000 };
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			const options = {
				method: cancel ? 'PATCH' : workflowExist ? 'PATCH' : 'POST',
				headers: {
					'Content-Type': 'application/json;charset=utf-8',
					'C2i-User': user.name,
					Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
				},
				body: cancel
					? JSON.stringify({
							statut: workflowStatutsOptions.modalOptions.find((o) => o.label === 'En cours').value,
					  })
					: JSON.stringify({
							quote_number: quoteNumber,
							invoice_number: invoiceNumber,
							statut: statut?.value ?? null, // "null" because "undefined" doesn't change the value of statut in back
							next_action_date: nextActionDate ? nextActionDate.toISOString().slice(0, 10) : null,
					  }),
			};

			try {
				const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/licenses/${licenseId}/workflow`, options);
				if (response.ok) {
					getLicenseWorkflow();
					toastNotification = { severity: 'success', detail: 'Informations enregistrées !', life: 3000 };
				}
			} catch (error) {
				console.log(error);
			}
		}

		toast.current.show(toastNotification);
	};

	const renewWorkflow = async () => {
		let toastNotification = { severity: 'error', detail: 'Erreur lors du renouvellement !', life: 3000 };

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=utf-8',
				'C2i-User': user.name,
				Authorization: `Api-Key ${process.env.REACT_APP_BACKEND_API_KEY}`,
			},
			body: JSON.stringify({
				quote_number: quoteNumber,
				invoice_number: invoiceNumber,
				statut: statut?.value ?? null, // "null" because "undefined" doesn't change the value of statut in back
			}),
		};

		try {
			const archiveResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/licenses/${licenseId}/workflow/archive`, options);
			if (archiveResponse.ok) {
				const workflowResponse = await deleteWorkflow();
				if (workflowResponse.ok) {
					getLicenseWorkflow();
					getLicenseWorkflowArchives();
					toastNotification = { severity: 'success', detail: 'Renouvellement effectué !', life: 3000 };
				}
			}
		} catch (error) {
			console.log(error);
		}

		toast.current.show(toastNotification);
	};

	useEffect(() => {
		getLicenseWorkflow();
	}, [workflowStatutsOptions, licenseId, user]);

	useEffect(() => {
		const initialNextActionDate = initialValues.nextActionDate ? initialValues.nextActionDate.toDateString() : null;
		const formNextActionDate = nextActionDate ? nextActionDate.toDateString() : null;

		if (initialValues.quoteNumber !== quoteNumber || initialValues.invoiceNumber !== invoiceNumber || initialValues.statut !== statut || initialNextActionDate !== formNextActionDate) {
			setFormChanged(true);
		} else {
			setFormChanged(false);
		}
	}, [initialValues, quoteNumber, invoiceNumber, statut, nextActionDate]);

	return (
		<div className='workflow-form'>
			<div>
				<label htmlFor='workflow-statut'>Statut :</label>
				<Select
					className='workflow-form-select'
					id='workflow-statut'
					menuPlacement='top'
					options={workflowStatutsOptions.modalOptions}
					isClearable={true}
					value={statut}
					onChange={(o) => setStatut(o)}
					isDisabled={workflowFinished}
				/>
			</div>
			<div>
				<label htmlFor='workflow-quote-number'>Numéro de devis :</label>
				<InputText className='workflow-form-input' id='workflow-quote-number' value={quoteNumber} onChange={(e) => setQuoteNumber(e.target.value)} disabled={workflowFinished} />
			</div>
			<div>
				<label htmlFor='workflow-invoice-number'>Numéro de facture :</label>
				<InputText className='workflow-form-input' id='workflow-invoice-number' value={invoiceNumber} onChange={(e) => setInvoiceNumber(e.target.value)} disabled={workflowFinished} />
			</div>
			<div>
				<label htmlFor='workflow-next-action-date'>Date prochaine action :</label>
				<Calendar
					className='workflow-form-input'
					id='workflow-next-action-date'
					value={nextActionDate}
					onChange={(e) => setNextActionDate(e.value)}
					showButtonBar
					disabled={workflowFinished}
				/>
			</div>
			<div className='workflow-form-buttons'>
				{workflowFinished ? (
					<>
						<button onClick={() => updateWorkflow(true)}>Annuler</button>
						<button onClick={renewWorkflow}>Renouveler</button>
					</>
				) : (
					formChanged && <button onClick={() => updateWorkflow(false)}>Enregistrer</button>
				)}
			</div>
		</div>
	);
};
